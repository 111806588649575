<template>
  <section class="baseStepper">
    <div class="baseStepper__header">
      <slot name="header">
        <p class="baseStepper__navigation">{{ $t('STEP') }} {{ current_step }}/{{ totalSteps }}</p>
      </slot>
      <q-linear-progress color="stepper" track-color="stepper-track" :value="progress" class="q-mt-md" />
    </div>
    <div class="baseStepper__step">
      <template v-for="(step, index) of steps">
        <div v-if="index + 1 === current_step" :key="index" data-slot="step">
          <slot :name="step.name" />
        </div>
      </template>
    </div>
    <slot v-if="!noNavigator" name="navigator">
      <div class="baseStepper__navigator">
        <BaseButton v-if="current_step > 1" icon="angular-arrow" label="Back" :icon_style="{ height: '23px', transform: 'rotate(180deg)', width: '16px' }" @click.native="handlePrevStep" />
        <BaseButton
          class="baseStepper__next"
          icon-on-right
          :disabled="isNextDisabled"
          :icon="rightButtonIcon"
          :label="rightButtonLabel"
          :custom_style="nextStepButtonStyle"
          :icon_style="{ height: '23px', width: '16px' }"
          @click.native="handleNextStep"
        />
      </div>
    </slot>
  </section>
</template>

<script>
// Vuex
import { mapGetters, mapActions } from 'vuex';

// Base Components
import BaseButton from '@base/BaseButton.vue';

// Helpers
import helpers from '@/utils/helpers';

export default {
  name: 'BaseStepper',
  components: {
    BaseButton
  },
  props: {
    /**
     * Completed label to be shown in the right button on the last step of stepper
     */
    completedLabel: { default: '', type: String },
    /**
     * Use this prop if you want to handle stepper next and prev navigation from outside this component
     */
    handleNavigation: { default: false, type: Boolean },
    /**
     * True if need to hide the navigator
     */
    noNavigator: { default: false, type: Boolean },
    /**
     * Steps
     */
    steps: { default: () => [], type: Array }
  },
  data() {
    return {
      navigation_step: 1
    };
  },
  computed: {
    ...mapGetters({
      activeStep: 'stepper/getStep',
      isNextDisabled: 'stepper/isNextDisabled'
    }),
    /**
     * Current active step
     */
    current_step() {
      if (this.handleNavigation) {
        if (this.activeStep >= this.totalSteps) return this.totalSteps;
        return this.activeStep;
      }

      return this.navigation_step;
    },
    /**
     * Check if stepper is on last step
     */
    isStepperCompleted() {
      return this.current_step === this.totalSteps;
    },
    /**
     * Style of the right button
     */
    nextStepButtonStyle() {
      const bgColor = {
        false: helpers.getCssVariable('--main-btn-color'),
        true: helpers.getCssVariable('--main-btn-color')
      };
      return {
        'background-color': bgColor[`${this.isStepperCompleted}`],
        height: '44px'
      };
    },
    /**
     * Retrieving the stepper progress based on the current step and the total steps
     */
    progress() {
      return this.current_step / this.totalSteps;
    },
    /**
     * Right button action icon
     */
    rightButtonIcon() {
      const icon = {
        true: '',
        false: 'angular-arrow'
      };

      return icon[`${this.isStepperCompleted}`];
    },
    /**
     * Right button action label
     */
    rightButtonLabel() {
      const label = {
        true: this.completedLabel,
        false: 'Next step'
      };
      return label[`${this.isStepperCompleted}`];
    },
    /**
     * Total stepper steps
     */
    totalSteps() {
      return this.steps.length;
    }
  },
  watch: {
    steps() {
      this.updateSteps(this.steps);
    }
  },
  beforeDestroy() {
    this.resetState();
  },
  methods: {
    ...mapActions({
      resetState: 'stepper/resetState',
      updateSteps: 'stepper/updateSteps'
    }),
    /**
     * Update the current step and emit event
     */
    handlePrevStep() {
      if (this.handleNavigation) {
        this.$emit('prev-step');
        return;
      }

      this.navigation_step--;
    },
    /**
     * Update the current step and emit event
     */
    handleNextStep() {
      if (this.handleNavigation) {
        const event = {
          true: 'complete:stepper',
          false: 'next-step'
        };

        this.$emit(event[this.isStepperCompleted]);
        return;
      }

      if (!this.isStepperCompleted) this.navigation_step++;
    }
  }
};
</script>

<style lang="scss">
.text-stepper {
  color: var(--main-text-color);
}
.bg-stepper {
  background: var(--main-text-color);
}

.text-stepper-track {
  color: var(--stepper-track);
}
.bg-stepper-track {
  background: var(--stepper-track);
}

.baseStepper {
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;

  &__navigator {
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
  }

  &__next {
    margin-left: auto;
  }

  &__navigation {
    color: var(--main-text-color);
    font-family: $inter-regular;
    font-size: calculateRem(16px);
    font-weight: bold;
    margin: 0;
    text-align: left;
  }

  &__step {
    min-height: 0;

    > div {
      height: 100%;
      position: relative;
    }
  }
}
</style>
