// Api
import { UserApi } from '@api/index';

const emailValidation = {
  methods: {
    /**
     * Method used to validate the email address. It sends the request
     * to BE to check if the email is already used.
     */
    async handleEmailValidation(value) {
      if (!this.$v.userInfo.user.email.$invalid) {
        const { statusCode, data } = await UserApi.checkEmailExistence(value);
        let isInputValid = false;

        if (statusCode === 200 && data) {
          isInputValid = !data.checker;
        }

        this.manuallyChangeFormValidity('email', isInputValid);

        return isInputValid || this.$t('FORM_VALIDATION.EMAIL_EXISTS');
      }
      return false;
    }
  }
};

export const emailValidationFlashyShoots = {
  methods: {
    /**
     * Method to validate the email address. Sends a request to BE to check if the email is already in use.
     */
    async handleEmailValidation(value) {
      if (this.$v.userData.email && !this.$v.userData.email.$invalid) {
        const { statusCode, data } = await UserApi.checkEmailExistence(value);
        let isInputValid = false;

        if (statusCode === 200 && data) {
          isInputValid = !data.checker;
        }

        // this.$set(this.$v.userData.email, '$manual', isInputValid);
        this.userData.emailExists = !isInputValid;

        return isInputValid || 'This email is already in use. Please use a different email.';
      }
      return false;
    }
  }
};

export default emailValidation;
