<template>
  <q-select
    class="baseSelect"
    :clearable="clearable"
    :bg-color="bgColor || ''"
    :filled="bgColor ? true : false"
    :hide-selected="false"
    :fill-input="false"
    dense
    menu-anchor="bottom left"
    outlined
    ref="baseSelect"
    :disable="disabled"
    :value="value"
    :error="error"
    :errorMessage="errorMessage"
    popup-content-class="baseSelectDropdown"
    :options="filteredOptions"
    :label="getLabel"
    :use-input="useInput"
    :emit-value="emitValue"
    behavior="menu"
    @clear="$emit('update:value', '')"
    @input="$emit('update:value', $event)"
    @filter="filterResults"
  >
  </q-select>
</template>

<script>
export default {
  name: 'BaseSelectWithFilter',
  model: {
    event: 'update:value',
    prop: 'value'
  },
  props: {
    /**
     * Background color. If not passed the background will be transparent
     */
    bgColor: { default: '', type: String },
    /**
     * True if input should be clearable
     */
    clearable: { default: true, type: Boolean },
    /**
     * Set to true if want to be disabled
     */
    disabled: { default: false, type: Boolean },
    /**
     * If set to true, it returns the whole option object
     * Otherwise it returns a String with the option value.
     */
    emitValue: { default: false, type: Boolean },
    error: { default: false, type: Boolean },
    errorMessage: { default: '', type: String },
    /**
     * Label of input field
     */
    label: { default: undefined, type: String },
    /**
     * Set to true if the field is mandatory
     */
    mandatory: { default: false, type: Boolean },
    /**
     * Select options
     */
    options: { default: () => [], type: Array },
    /**
     * Custom field to use as option label
     */
    optionLabel: { default: '', type: String },
    /**
     * Custom field to use as option value
     */
    optionValue: { default: '', type: String },
    /**
     * Set to true if you want to filter the select
     */
    useInput: { default: false, type: Boolean },
    /**
     * Value
     */
    value: { default: null, type: [String, Object, Number] }
  },
  data() {
    return {
      filteredOptions: [],
      optionsArr: []
    };
  },
  computed: {
    /**
     * Returning the label with * if its mandatory
     */
    getLabel() {
      if (this.mandatory) return `${this.label}*`;
      return this.label;
    }
  },
  watch: {
    options: {
      handler(val) {
        // If all the options are strings don't change the array
        if (val.every(opt => typeof opt === 'string')) {
          this.optionsArr = val;
          this.filteredOptions = val;
          return;
        }

        let optionsList = [];

        val.forEach(option => {
          if ((!option.group || option.group === 'undefined') && !option.disabled) {
            optionsList.push({
              ...option,
              label: option[this.optionLabel || 'label'] ? option[this.optionLabel || 'label'] : option,
              value: option[this.optionValue || 'value'] ? option[this.optionValue || 'value'] : option
            });
          } else {
            if (option.group) {
              optionsList.push({
                label: option.group.toUpperCase(),
                value: option.group,
                disable: true
              });
            }
            if (option.labels && option.labels.length > 0) option.labels.forEach(item => optionsList.push({ label: item.label, value: item.value }));
          }
        });

        this.optionsArr = optionsList;
        this.filteredOptions = optionsList;
      },
      immediate: true
    }
  },
  methods: {
    /**
     * Method used to filter the results when the user enters something in the search field
     */
    filterResults(val, update) {
      if (val === '') {
        update(() => {
          this.filteredOptions = this.optionsArr;
        });
        return;
      }

      update(() => {
        const searchQuery = val.toLowerCase();
        this.filteredOptions = this.optionsArr.filter(option => {
          const label = option.label || option;
          return label.toLowerCase().indexOf(searchQuery) > -1;
        });
      });
    }
  }
};
</script>

<style lang="scss">
.item-group {
  cursor: pointer;

  &:hover {
    background-color: #ddd;
  }

  &--is-disabled {
    color: var(--secondary-text-color);
    font-family: $inter-regular;
    font-size: 12px;
  }
}

.baseSelectDropdown {
  max-height: 200px;
  min-width: 380px !important;

  @include responsive($max: md) {
    min-width: unset !important;
  }
}

.baseSelect {
  .q-field__control {
    border-radius: 10px;
    color: var(--main-text-color) !important;

    &::before {
      border: 1px solid var(--form-fields-border-color);
    }
  }

  .q-field__native {
    white-space: nowrap;
  }

  .q-field__label {
    color: var(--secondary-text-color);
  }
  .q-field__focusable-action {
    display: none !important;
  }
  .q-field--dense .q-field__label {
    font-size: 12px;
  }
}
.q-position-engine {
  max-height: 250px;
}
</style>
