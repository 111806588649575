<template>
  <section :class="['stepLayout relative-position', { 'stepLayout--over-on-mobile': overOnMobile }]">
    <div>
      <p :class="['stepLayout__title', { 'stepLayout__title--with-subtitle': subtitle }]">{{ title }}</p>
      <p class="stepLayout__subtitle stepLayout__subtitle--small">{{ subtitle }}</p>
    </div>
    <div class="stepLayout__grid">
      <div v-if="$slots['leftColumn']" data-type="column">
        <p v-if="leftColumnTitle" class="stepLayout__subtitle">{{ leftColumnTitle }}</p>
        <slot name="leftColumn" />
      </div>
      <div v-if="$slots['rightColumn']" data-type="column">
        <p v-if="rightColumnTitle" class="stepLayout__subtitle">{{ rightColumnTitle }}</p>
        <slot name="rightColumn" />
      </div>
      <div v-if="$slots['column-overlay']" data-type="column-overlay">
        <p v-if="rightColumnTitle" class="stepLayout__subtitle">{{ rightColumnTitle }}</p>
        <slot name="column-overlay" />
      </div>
    </div>
  </section>
</template>

<script>
// Store
import { mapActions, mapGetters } from 'vuex';

/**
 * Component used as layout for all the registration stepper steps
 */
export default {
  name: 'StepLayout',
  props: {
    /**
     * Boolean value that tells if the step is valid and we are able to go to next one or not
     */
    isStepValid: { default: false, type: Boolean },
    /**
     * Left column title
     */
    leftColumnTitle: { default: '', type: String },
    /**
     * If set to true, the right column
     */
    overOnMobile: { default: false, type: Boolean },
    /**
     * Right column title
     */
    rightColumnTitle: { default: '', type: String },
    /**
     * Subtitle
     */
    subtitle: { default: '', type: String },
    /**
     * Title
     */
    title: { default: '', type: String }
  },
  computed: {
    ...mapGetters({
      isChangingStep: 'stepper/getIsChangingStep'
    })
  },
  watch: {
    /**
     * Whenever the user tries to go to the next step, validate fields
     */
    isChangingStep() {
      if (this.isChangingStep) this.$emit('validation:check');
      this.setIsChangingStep(false);
    }
  },
  methods: {
    ...mapActions({
      setIsChangingStep: 'stepper/setIsChangingStep'
    })
  }
};
</script>

<style lang="scss" scoped>
.stepLayout {
  display: grid;
  grid-template-rows: 1fr 9fr;
  height: 100%;

  &__grid {
    column-gap: 20px;
    display: grid;
    grid-template-columns: 5fr 5fr;
    min-height: 0;

    > div[data-type='column'],
    > div[data-type='column-overlay'] {
      min-height: 0;
      @include responsive($max: md) {
        margin-top: 10px;
        min-height: auto !important;
      }
    }
  }

  &__subtitle {
    @include inter-font($size: 14px, $color: var(--main-text-color));
    text-align: left;

    &--small {
      @include inter-font($size: 12px, $color: var(--main-text-color));
      margin-bottom: calculateRem(23px);
    }
    @include responsive($max: md) {
      margin: 0 0 10px;
    }
  }

  &__title {
    @include inter-font($size: 16px, $color: var(--main-text-color));
    font-weight: bold;
    margin-bottom: 23px;
    padding-top: 25.5px;
    text-align: left;

    &--with-subtitle {
      margin-bottom: calculateRem(17px);
    }
    @include responsive($max: md) {
      margin-bottom: 10px;
      padding-top: 15px;
    }
  }
}

@include responsive($max: mobileL) {
  .stepLayout {
    display: grid;
    grid-template-rows: 1fr 9fr;
    height: 100%;

    &__grid {
      display: grid;
      grid-template-columns: auto;
      grid-template-rows: auto auto;

      > div[data-type='column-overlay'] {
        background-color: var(--main-bg-color);
        height: 100vh;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 2;
      }
    }
  }
}
</style>
