<template>
  <StepLayout :title="$t('CREATE_ACCOUNT')">
    <template #leftColumn>
      <BaseInput v-model="$v.userInfo.user.name.$model" mandatory :error="$v.userInfo.user.name.$error" :label="$t('PERSONAL_DATA.FIRST_NAME')" />
      <BaseInput v-model="$v.userInfo.user.surname.$model" mandatory :error="$v.userInfo.user.surname.$error" :label="$t('PERSONAL_DATA.LAST_NAME')" />
      <BaseInput v-model="$v.userInfo.user.email.$model" :validators="[handleEmailValidation]" mandatory :error="$v.userInfo.user.email.$error" :label="$t('PERSONAL_DATA.EMAIL')" />
      <BaseInput type="password" v-model="$v.userInfo.password.$model" mandatory :error="$v.userInfo.password.$error" :label="$t('PASSWORD')" :error-message="$t('PASSWORD_REQ')" />
      <BaseCheckbox
        v-model="$v.termsAndConditionAccepted.$model"
        type="checkbox"
        :colors="{ bg: '#fff0', 'bg-active': '#000' }"
        :error="$v.termsAndConditionAccepted.$error"
        :errorMessage="$t('ACCEPT_TERMS_&_CONDITION')"
      >
        <template #label>
          <label class="registration__conditions" for="checkbox">
            {{ $t('ACCEPT') }}
            <a href="https://www.flashy.ae/terms-and-conditions" target="_blank" @click.stop> {{ $t('TERMS_AND_CONDITION') }}*</a>
          </label>
        </template>
      </BaseCheckbox>
    </template>
  </StepLayout>
</template>

<script>
// Base Components
import BaseCheckbox from '@base/BaseCheckbox.vue';
import BaseInput from '@base/BaseInput.vue';

// Components
import StepLayout from '@/components/registration/StepLayout.vue';

import { email, required } from 'vuelidate/lib/validators';

// Mixins
import helpers from '@utils/mixins/registration';
import emailValidation from '@utils/mixins/email-validation-mixin';

export default {
  name: 'Step2',
  mixins: [helpers, emailValidation],
  components: {
    BaseCheckbox,
    BaseInput,
    StepLayout
  },
  props: {
    /**
     * The user that is being created
     */
    user: { default: () => ({}), type: Object }
  },
  data() {
    return {
      userInfo: {
        ...this.user
      },
      termsAndConditionAccepted: false
    };
  },
  validations: {
    userInfo: {
      password: {
        required,
        valid: value => {
          const pwdRegex = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})');
          return pwdRegex.test(value);
        }
      },
      user: {
        email: {
          email,
          required
        },
        name: {
          required
        },
        surname: {
          required
        }
      }
    },
    termsAndConditionAccepted: {
      checked: val => {
        return val;
      }
    }
  },
  watch: {
    userInfo: {
      deep: true,
      immediate: true,
      handler: function () {
        this.checkValidation();
      }
    },
    termsAndConditionAccepted() {
      this.checkValidation();
    }
  }
};
</script>
<style lang="scss">
.registration {
  &__conditions {
    @include inter-font($size: 10px, $color: var(--box-bg-color));
    a {
      color: var(--box-bg-color);
    }
  }
  .q-field__bottom {
    padding-bottom: 8px;
    position: relative;
    transform: none;
    @include responsive($max: md) {
      min-height: 10px;
      padding: 3px;
    }
  }
  .q-field--with-bottom {
    padding-bottom: 0;
  }
}
</style>
