<template>
  <input class="baseLocation" :placeholder="placeholderText" :value="getValue" ref="autocomplete" type="text" />
</template>

<script>
export default {
  model: {
    event: 'update:value',
    prop: 'value'
  },
  data() {
    return {
      autocomplete: ''
    };
  },
  name: 'BaseLocation',
  props: {
    /*
     * Set to true if field is mandatory
     */
    mandatory: { default: false, type: Boolean },
    /*
     * set the placeholder
     *
     */
    placeholder: { default: '', type: String },
    /**
     * If true returns the address splitted into its components
     */
    splittedAddress: { default: false, type: Boolean },
    /**
     * Pass the code of the country to restrict the autocomplete results
     */
    country: { default: '', type: String },
    /**
     * Set the type of suggestions
     */
    type: { default: '', type: String },

    value: { default: '', type: [Object, String] }
  },
  computed: {
    /**
     * Returning input value based on type of value prop.
     * If it a splitted address (object) get one of its attributes
     */
    getValue() {
      if (this.splittedAddress) return this.value.formatted_address;

      return this.value;
    },
    placeholderText() {
      if (this.mandatory) return `${this.placeholder} *`;
      return this.placeholder;
    }
  },

  mounted() {
    const options = {
      types: (this.type && [this.type]) || undefined,
      componentRestrictions: { country: this.country }
    };

    // eslint-disable-next-line no-undef
    this.autocomplete = this.country ? new google.maps.places.Autocomplete(this.$refs.autocomplete, options) : new google.maps.places.Autocomplete(this.$refs.autocomplete);

    // eslint-disable-next-line no-undef
    google.maps.event.addListener(this.autocomplete, 'place_changed', () => {
      const place = this.autocomplete.getPlace() || {};

      let emitValue = place.formatted_address;
      const { address_components } = place;
      const country = address_components.find(({ types }) => types.includes('country')) || {};
      const locality =
        address_components.find(
          ({ types }) => types.includes('locality') || types.includes('postal_town') || types.includes('administrative_area_level_4') || types.includes('administrative_area_level_3')
        ) || {};
      const postal_code = address_components.find(({ types }) => types.includes('postal_code')) || {};

      const route = address_components.find(({ types }) => types.includes('route')) || {};

      if (this.splittedAddress) {
        emitValue = {
          city: locality.long_name,
          country: country.short_name,
          line1: route.long_name,
          postal_code: postal_code.long_name,
          state: country.long_name,
          formatted_address: place.formatted_address,
          utc_offset_minutes: place.utc_offset_minutes
        };

        this.$emit('update:value', emitValue);
        return;
      }

      if (this.type === '(regions)') {
        emitValue = place.address_components;
      }

      if (this.type === '(cities)') {
        emitValue = locality.long_name;
      }

      this.$emit('update:value', emitValue);
    });
  }
};
</script>

<style lang="scss" scoped>
.baseLocation {
  background-color: var(--main-bg-color);
  border: 1px solid var(--form-fields-border-color);
  border-radius: 10px;
  @include inter-font($size: 12px, $color: var(--main-text-color));
  height: 45px;
  padding: 15px 20px;
  outline: none;
  width: 100%;
}
</style>

<style lang="scss">
.pac-container {
  background-color: var(--secondary-bg-color);
  border: unset;
  border-radius: 10px;
  box-shadow: none;
  color: var(--main-text-color);
  font-family: $inter-regular;
  font-size: calculateRem(12px);
  margin-top: 5px;
  z-index: 99999;

  .pac-item {
    border-top: unset;
    padding: 10px 5px;
  }
}
</style>
