<template>
  <section class="registration">
    <template v-if="!registrationCompleted">
      <div class="registration__flashy-screen swipe">
        <img src="@/assets/logo.png" class="registration__flashy-logo" alt="logo" />
      </div>
      <div :class="['registration__wizard', { 'registration__wizard--large': large }]">
        <div>
          <slot name="wizard" />
        </div>
      </div>
    </template>
    <RegistrationCompleted v-if="registrationCompleted" :isSubClient="isSubClient" class="registration__wizard" />
  </section>
</template>

<script>
// Store
import { mapGetters } from 'vuex';
// Components
import RegistrationCompleted from './RegistrationCompleted.vue';

export default {
  name: 'Registration',
  components: {
    RegistrationCompleted
  },
  props: {
    /**
     * Prop used to pass the current user role
     */
    isSubClient: { default: false, type: Boolean },
    /**
     * Set to true to expand the wizard height to max (764px)
     */
    large: { default: false, type: Boolean }
  },
  computed: {
    ...mapGetters({
      registrationCompleted: 'user/getRegistrationCompleted',
      isAdmin: 'user/isAdmin'
    })
  }
};
</script>

<style lang="scss" scoped>
.registration {
  align-items: center;
  background-image: url('~@/assets/background_registration.png');
  display: flex;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 100vw;

  &__hidden {
    display: none;
  }
  &__flashy-screen {
    display: none;
  }

  &__wizard {
    background-color: var(--secondary-bg-color);
    border-radius: 10px;
    height: 557px;
    width: 750px;

    > div {
      height: 100%;
      padding: 40px 40px 30px 40px;
      width: 100%;
    }

    &--large {
      height: 764px;
    }
  }
}

@include responsive($max: mobileL) {
  .registration {
    &__hidden {
      display: block;
    }
    &__wizard {
      border-radius: 0;
      height: 100vh;
      width: 100vw;

      &--large {
        height: 100vh;
      }
    }

    &__flashy-screen {
      background: var(--main-btn-color);
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 9;
      &.swipe {
        animation-duration: 2s;
        animation-name: swipe;
        animation-timing-function: ease-in-out;
        transform: translateX(-100%);
      }
    }
    &__flashy-logo {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 60%;
    }
  }
}
@keyframes swipe {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}
</style>
