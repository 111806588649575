import { render, staticRenderFns } from "./BusinessRegistration.vue?vue&type=template&id=536cb068&scoped=true&"
import script from "./BusinessRegistration.vue?vue&type=script&lang=js&"
export * from "./BusinessRegistration.vue?vue&type=script&lang=js&"
import style0 from "./BusinessRegistration.vue?vue&type=style&index=0&id=536cb068&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "536cb068",
  null
  
)

export default component.exports