<template>
  <div class="registrationCompleted">
    <img src="@/assets/svg/check-circle.svg" class="registrationCompleted__check" alt="check" />
    <h4>{{ $t('REGISTRATION_COMPLETED') }}</h4>
    <div class="registrationCompleted__textBeforeLogin">
      <div class="text" v-show="!isSubClient && !isAdmin">{{ $t('CHECK_EMAIL') }}</div>
      <div class="text" v-show="!isAdmin && isSubClient" v-html="$t('SUBCLIENT.REGISTRATION_TEXT')"></div>
      <div class="text" v-show="isAdmin" v-html="$t('Account successfully created. The customer will receive an email to set a password.')"></div>
    </div>
    <div class="registrationCompleted__footer">
      <!-- <BaseButton :label="$t('RESEND_EMAIL')" @click.native="handleResendEmail" type="default" /> -->
      <BaseButton v-show="!isAdmin" type="dark" :custom_style="{ color: 'var(--main-btn-color)' }" :label="$t('GO_TO_LOGIN')" @click.native="handleLogin" />
      <BaseButton v-show="isAdmin" type="dark" :custom_style="{ color: 'var(--main-btn-color)' }" :label="$t('GO BACK')" @click.native="handleGoBack" />
    </div>
  </div>
</template>
<script>
//button
import BaseButton from '@base/BaseButton.vue';
// Utils
import { setCookie } from '@utils/cookies';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'RegistrationCompleted',
  components: {
    BaseButton
  },
  computed: {
    ...mapGetters({
      isAdmin: 'user/isAdmin'
    })
  },
  props: {
    /**
     * Prop used to change the registration completed step's text if the user is a sub-client
     */
    isSubClient: { default: false, type: Boolean },
    /**
     * Use this prop to set the button type (danger, warning, default),
     * this will update its style
     */
    type: { default: '', type: String }
  },
  methods: {
    ...mapActions({
      resetState: 'stepper/resetState',
      resetUserState: 'user/resetState',
      setRegistrationCompleted: 'user/setRegistrationCompleted'
    }),
    /**
     * Removing the login token and redirecting to login page
     */
    async handleLogin() {
      await setCookie({ cookieName: 'jwt', cookieValue: '', expiration: 'Thu, 01 Jan 1970 00:00:00 GMT' });
      this.$router.push({ name: 'home' });
    },
    async handleGoBack() {
      this.setRegistrationCompleted(false);
      this.resetState();
      this.resetUserState();
      this.$router.push({ name: 'home' });
    },
    /**
     * use to request a new validation by email
     */
    handleResendEmail() {
      this.$store.dispatch('notification/addSuccessNotification', this.$t('NOTIFICATIONS.RE-SEND_EMAIL'));
    }
  }
};
</script>
<style lang="scss">
.text {
  text-align: center;
}
.registrationCompleted {
  align-items: center;
  background: var(--main-btn-color) !important;
  color: var(--box-bg-color);
  display: flex;
  flex-direction: column;
  font-family: $inter-regular;
  font-weight: 400;
  height: 100%;
  justify-content: space-between;
  padding: 70px;
  width: 100%;

  &__check {
    height: 50px;
    margin-top: 50px;
    margin-bottom: 50px;
    width: 50px;
  }

  h4 {
    font-size: calculateRem(19px);
    font-weight: bold;
    margin: 0;
    line-height: unset;
  }

  &__textBeforeLogin {
    display: block;
    margin: auto;
    text-align: left;
    max-width: 475px;
    width: 100%;

    @include responsive($max: md) {
      width: auto;
    }

    p {
      font-size: calculateRem(14px);
      margin: 0;
      white-space: pre;
    }

    li {
      display: block;
    }

    li:before {
      content: counter(count) ' - ';
      counter-increment: count;
    }

    ol {
      counter-reset: count 0;
      list-style-type: none;
      padding-inline-start: 0;
      margin: 0;
    }
  }

  &__footer {
    display: flex;
    justify-content: center;

    .baseButton {
      &-email {
        color: var(--secondary-btn-color) !important;
        margin-right: 5px;
      }
    }
  }
}
</style>
