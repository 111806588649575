import { mapActions } from 'vuex';

const helpers = {
  data() {
    return {
      formFieldsAreValid: true,
      formFieldsValidity: {},
      isValid: false
    };
  },
  methods: {
    ...mapActions({
      setIsDisabled: 'stepper/setIsDisabled',
      updateUser: 'user/updateWizardUser'
    }),
    /**
     * Method used to manually change the form validity. Usefull if your form includes inputs that
     * can't be validated with vuelidate (for example because their validators are asynchronous)
     * @param {String} field
     * @param {Boolean} isValid
     */
    manuallyChangeFormValidity(field, isValid) {
      this.formFieldsValidity[field] = isValid;
      // Form is valid if formFieldsValidity object is empty or all its values are truthy
      this.formFieldsAreValid = Object.keys(this.formFieldsValidity).length === 0 || Object.values(this.formFieldsValidity).every(value => value === true);
    },
    /**
     * Checking validation step. If it's valid, StepLayout will handle the next step rendering
     */
    checkValidation() {
      this.isValid = !this.$v.$invalid;

      this.setIsDisabled(!this.isValid || !this.formFieldsAreValid);

      if (this.isValid && this.formFieldsAreValid) {
        this.updateUser(this.userInfo);
      }
    }
  },
  watch: {
    formFieldsAreValid() {
      this.checkValidation();
    }
  }
};

export default helpers;
