<template>
  <Registration :isSubClient="isSubClient">
    <template #wizard>
      <div v-if="isLoading" class="registration__loader"><img class="registration__logo" src="@/assets/logo.png" /></div>
      <BaseStepper handle-navigation :completed-label="$t('COMPLETE_REGISTRATION')" :steps="steps" @complete:stepper="completeRegistration" @next-step="goToNextStep" @prev-step="goToPrevStep">
        <template #COMPANY_INFO>
          <Step1 v-if="user != null" :user="{ ...user }" :isSubClient="isSubClient" />
        </template>
        <template #CREATE_ACCOUNT>
          <Step2 :user="{ ...user }" />
        </template>
        <template #COMPANY_CONTACTS>
          <Step3 :user="{ ...user }" />
        </template>
      </BaseStepper>
    </template>
  </Registration>
</template>

<script>
// Store
import { mapGetters, mapActions } from 'vuex';

// Base Components
import BaseStepper from '@base/BaseStepper.vue';

// Components
import Registration from './Registration.vue';
import Step1 from './business/Step1.vue';
import Step2 from './business/Step2.vue';
import Step3 from './business/Step3.vue';

// Api
import { UserApi } from '@api/index';

// Utils
import countriesHelper from '@utils/countries/countries';

export default {
  name: 'BusinessRegistration',
  metaInfo() {
    return {
      title: 'Flashy ',
      titleTemplate: '%s  | – Business Registration',
      htmlAttrs: { lang: 'en-US' },
      meta: [{ name: 'description', content: 'Flashy is changing the way photography works. Please register for our platform.' }]
    };
  },
  components: {
    BaseStepper,
    Registration,
    Step1,
    Step2,
    Step3
  },
  data() {
    return {
      isLoading: true,
      isSubClient: false,
      steps: [
        {
          name: 'COMPANY_INFO'
        },
        {
          name: 'CREATE_ACCOUNT'
        }
      ],
      user: null
    };
  },
  computed: {
    ...mapGetters({
      wizardUser: 'user/getWizardUser',
      isAdmin: 'user/isAdmin'
    })
  },
  created() {
    if (!this.$route.query.guid) {
      // Add company contacts to registration steps
      this.steps = [
        ...this.steps,
        {
          name: 'COMPANY_CONTACTS'
        }
      ];
      this.user = this.wizardUser;
      this.isLoading = false;
      return;
    }

    this.isSubClient = true;
    this.getBusinessData();
  },
  methods: {
    ...mapActions({
      goToNextStep: 'stepper/goToNextStep',
      goToPrevStep: 'stepper/goToPrevStep',
      setRegistrationCompleted: 'user/setRegistrationCompleted',
      updateUser: 'user/updateWizardUser'
    }),
    async completeRegistration() {
      const { guid } = this.$route.query || { guid: null };
      const business = await UserApi.createBusiness(this.user, guid, this.isAdmin);

      if (business.statusCode === 200) this.setRegistrationCompleted(true);
    },
    async getBusinessData() {
      this.isLoading = true;

      const { guid } = this.$route.query || { guid: null };

      const { statusCode, data } = guid && (await UserApi.getBusinessByGuid(guid));

      if (statusCode === 200) {
        const { company_name, country, industry, main_location } = data.user;
        const { label } = countriesHelper
          .getGroupedContries()
          .map(({ labels }) => labels)
          .flat()
          .find(({ value }) => value.toLowerCase() === country.toLowerCase());

        const userData = {
          ...this.wizardUser,
          additionalInformations: {
            ...this.wizardUser.additionalInformations,
            company_name: company_name,
            country: {
              label,
              value: country
            },
            industry: industry,
            main_location: main_location
          }
        };
        this.updateUser(userData);
        this.isLoading = false;
      } else {
        this.$store.dispatch('user/resetState');
        this.$store.dispatch('notification/addFailureNotification', this.$t('SUBCLIENT.REGISTRATION_NO_SUPERCLIENT'));
        await new Promise(resolve => setTimeout(resolve, 3000));
        window.location.replace(`${process.env.VUE_APP_FE_DOMAIN}/`);
      }
    }
  },
  watch: {
    wizardUser(val) {
      this.user = val;
    }
  },
  beforeDestroy() {
    this.user = null;
  }
};
</script>
<style lang="scss" scoped>
.registration {
  &__logo {
    width: 8rem;
    animation: zoom-in-out 2s linear infinite;
  }
  &__loader {
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 5;
  }
}

@keyframes zoom-in-out {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  50% {
    opacity: 0.5;
    transform: scale(0.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
