<template>
  <StepLayout :title="$t('POINT_OF_CONTACT')" :subtitle="$t('POINT_OF_CONTACT_TIP')">
    <template #leftColumn>
      <BaseInput v-model="$v.userInfo.additionalInformations.contact_name.$model" mandatory :error="$v.userInfo.additionalInformations.contact_name.$error" :label="$t('PERSONAL_DATA.FIRST_NAME')" />
      <BaseInput
        v-model="$v.userInfo.additionalInformations.contact_surname.$model"
        mandatory
        :error="$v.userInfo.additionalInformations.contact_surname.$error"
        :label="$t('PERSONAL_DATA.LAST_NAME')"
      />
      <BaseInput v-model="$v.userInfo.additionalInformations.contact_email.$model" mandatory :error="$v.userInfo.additionalInformations.contact_email.$error" :label="$t('PERSONAL_DATA.EMAIL')" />
      <BasePhoneNumber v-model="$v.userInfo.additionalInformations.contact_phone.$model" mandatory />
    </template>
  </StepLayout>
</template>

<script>
// Base Components
import BaseInput from '@base/BaseInput.vue';
import BasePhoneNumber from '@base/BasePhoneNumber.vue';

// Components
import StepLayout from '@/components/registration/StepLayout.vue';

import { email, required } from 'vuelidate/lib/validators';

// Mixins
import helpers from '@utils/mixins/registration';

export default {
  name: 'Step3',
  mixins: [helpers],
  components: {
    BaseInput,
    BasePhoneNumber,
    StepLayout
  },
  props: {
    /**
     * The user that is being created
     */
    user: { default: () => ({}), type: Object }
  },
  data() {
    return {
      userInfo: {
        ...this.user
      }
    };
  },
  validations: {
    userInfo: {
      additionalInformations: {
        contact_email: {
          email,
          required
        },
        contact_name: {
          required
        },
        contact_surname: {
          required
        },
        contact_phone: {
          required,
          valid: value => {
            const numberRegex = new RegExp(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/);
            return numberRegex.test(value.replace(/\s/g, ''));
          }
        }
      }
    }
  },
  watch: {
    userInfo: {
      deep: true,
      immediate: true,
      handler: function () {
        this.checkValidation();
      }
    }
  }
};
</script>
