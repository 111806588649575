<template>
  <StepLayout :title="$t('COMPANY.COMPANY_INFO')">
    <template #leftColumn>
      <BaseInput
        v-model="$v.userInfo.additionalInformations.company_name.$model"
        mandatory
        :disabled="isSubClient"
        class="step1__input"
        :error="$v.userInfo.additionalInformations.company_name.$error"
        :label="$t('COMPANY.NAME')"
      />
      <BaseSelectWithFilter
        class="step1__input"
        mandatory
        :disabled="isSubClient"
        useInput
        :value="$v.userInfo.additionalInformations.industry.$model"
        :error="$v.userInfo.additionalInformations.industry.$error"
        :label="$t('COMPANY.INDUSTRY')"
        :options="industryOptions"
        @update:value="userInfo.additionalInformations.industry = $event"
      />
      <BaseSelectWithFilter
        class="step1__input"
        mandatory
        :disabled="isSubClient"
        useInput
        :emitValue="false"
        :value="$v.userInfo.additionalInformations.country.$model"
        :error="$v.userInfo.additionalInformations.country.$error"
        :label="$t('COMPANY.COUNTRY')"
        :options="countryOptions"
        @update:value="userInfo.additionalInformations.country = $event"
      />
      <BaseLocation
        :style="{ 'background-color': '#ffffff', height: '40px', padding: '6px 12px', 'font-size': '14px' }"
        mandatory
        :disabled="isSubClient"
        :value="$v.userInfo.additionalInformations.main_location.$model"
        :error="$v.userInfo.additionalInformations.main_location.$error"
        class="step1__input"
        :placeholder="$t('COMPANY.MAIN_LOCATION')"
        :country="userInfo.additionalInformations && userInfo.additionalInformations.country ? userInfo.additionalInformations.country.value : ''"
        :key="userInfo.additionalInformations.country.value"
        @update:value="userInfo.additionalInformations.main_location = $event"
      />
    </template>
  </StepLayout>
</template>

<script>
import { getCookie } from '@utils/cookies';
import jwt_decode from 'jwt-decode';
import store from '../../../store/index';

// Base Components
import BaseInput from '@base/BaseInput.vue';
import BaseSelectWithFilter from '@base/BaseSelectWithFilter.vue';

// Components
import StepLayout from '@/components/registration/StepLayout.vue';

import { required } from 'vuelidate/lib/validators';

// Utils
import countriesHelper from '@utils/countries/countries';

// Mixins
import helpers from '@utils/mixins/registration';
import BaseLocation from '@/components/base/BaseLocation.vue';

export default {
  name: 'Step1',
  mixins: [helpers],
  components: {
    BaseInput,
    BaseSelectWithFilter,
    StepLayout,
    BaseLocation
  },
  props: {
    /**
     * Prop used to disable company data is the user is a sub-client
     */
    isSubClient: { default: false, type: Boolean },
    /**
     * The user that is being created
     */
    user: { default: () => ({}), type: Object }
  },
  data() {
    return {
      countryOptions: countriesHelper.getGroupedContries(),
      industryOptions: [
        this.$t('INDUSTRY_LIST.AGRICULTURE'),
        this.$t('INDUSTRY_LIST.CONSTRUCTION'),
        this.$t('INDUSTRY_LIST.CORPORATE'),
        this.$t('INDUSTRY_LIST.E-COMMERCE'),
        this.$t('INDUSTRY_LIST.EDUCATION'),
        this.$t('INDUSTRY_LIST.EVENTS_WEDDINGS'),
        this.$t('INDUSTRY_LIST.FOOD_DELIVERY'),
        this.$t('INDUSTRY_LIST.FOOD_PRODUCTION'),
        this.$t('INDUSTRY_LIST.FOOD_TECH'),
        this.$t('INDUSTRY_LIST.GOVERNMENT'),
        this.$t('INDUSTRY_LIST.HEALTHCARE'),
        this.$t('INDUSTRY_LIST.HOSPITALITY'),
        this.$t('INDUSTRY_LIST.MANIFACTURING'),
        this.$t('INDUSTRY_LIST.PUBLIC_ADMINISTRATION'),
        this.$t('INDUSTRY_LIST.REAL_ESTATE'),
        this.$t('INDUSTRY_LIST.SOCIAL_MEDIA'),
        this.$t('INDUSTRY_LIST.SPORTS_FITNESS'),
        this.$t('INDUSTRY_LIST.TRANSPORTATION'),
        this.$t('INDUSTRY_LIST.WHOLESALE'),
        this.$t('INDUSTRY_LIST.INDIVIDUAL'),
        this.$t('INDUSTRY_LIST.OTHER')
      ],
      userInfo: {
        ...this.user
      }
    };
  },
  validations: {
    userInfo: {
      additionalInformations: {
        company_name: {
          required
        },
        country: {
          required
        },
        industry: {
          required
        },
        main_location: {
          required
        }
      }
    }
  },
  watch: {
    userInfo: {
      deep: true,
      immediate: true,
      handler: function () {
        this.checkValidation();
      }
    }
  },
  created() {
    const jwt = getCookie('jwt');
    let decoded_token;

    if (jwt) {
      decoded_token = jwt_decode(jwt);

      // Setting user on store
      store.dispatch('user/saveUser', { ...decoded_token });
    }
  }
};
</script>
